import React, { useState } from "react";
import { PropTypes } from "prop-types";
import ContactForm from "./contactform.js";
import "../styles/body.css";
import "../styles/about.css";
import "../styles/evenpages.css";
import "../styles/oddpages.css";
import "../styles/picturegallery.css";
import "../styles/specialpages.css";
import "../styles/contact.css";
import IconToggle from "../scripts/iconToggle";
import chevup from "../icons/chevron-upB.svg";
import chevdown from "../icons/chevron-downB.svg";
import chevdownW from "../icons/chevron-down.svg";

export default function ReturnBody({
  subtitle,
  title,
  customid,
  pageNumber,
  pageValue,
  backgroundimage,
  p1Text,
  p2Text,
  p3Text,
}) {
  ReturnBody.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    customid: PropTypes.string.isRequired,
    pageNumber: PropTypes.string,
    pageValue: PropTypes.string,
    backgroundimage: PropTypes.string,
    p1Text: PropTypes.string,
    p2Text: PropTypes.string,
    p3Text: PropTypes.string,
  };

  function BodyHeader() {
    return (
      <div className={pageNumber === "about" ? "" : "body-hide-feature"}>
        <IconToggle />
        <div className="brickbackground"></div>
      </div>
    );
  }

  function CallMeButton() {
    return (
      <div className="call-me-container">
        <a href="tel:705.493.5330" className="call-me-link">
          <h2 id="call-me-heading">Call</h2>
          <h3 className="call-me-number">705.493.5330</h3>
        </a>
      </div>
    );
  }

  function BodyChevUp() {
    return (
      <div>
        {pageNumber === "about" ? (
          ""
        ) : (
          <img
            className="body-chevs body-chevup"
            src={chevup}
            alt="CHEVUP"
          ></img>
        )}
      </div>
    );
  }
  function BodyChevDown() {
    return (
      <div className={pageNumber === "about" ? "body-chev-bar-black" : ""}>
        {pageNumber === "about" ? (
          <img
            className="body-chevs body-chevdown"
            src={chevdownW}
            alt="CHEVDOWN"
          ></img>
        ) : pageNumber === "last even" || pageNumber === "last odd" ? (
          <p>{pageValue}</p>
        ) : (
          <img
            className="body-chevs body-chevdown"
            src={chevdown}
            alt="CHEVDOWN"
          ></img>
        )}
      </div>
    );
  }

  function ImageGallery() {
    const [expandedImage, setExpandedImage] = useState(null);

    const toggleSize = (imageId) => {
      setExpandedImage((prev) => (prev === imageId ? null : imageId));
    };

    return (
      <div
        className={
          pageNumber === "about" || pageNumber === "last"
            ? "body-hide-feature"
            : "picture-gallery"
        }
      >
        <div
          id="image-one"
          className={`images image1 ${
            expandedImage === "image-one" ? "expanded" : ""
          }`}
          onClick={() => toggleSize("image-one")}
        ></div>
        <div
          id="image-two"
          className={`images image2 ${
            expandedImage === "image-two" ? "expanded" : ""
          }`}
          onClick={() => toggleSize("image-two")}
        ></div>
        <div
          id="image-three"
          className={`images image3 ${
            expandedImage === "image-three" ? "expanded" : ""
          }`}
          onClick={() => toggleSize("image-three")}
        ></div>
      </div>
    );
  }

  function ParagraphPage() {
    return (
      <div className="text-body">
        <div className="thin-line">
          <h2>{subtitle}</h2>
        </div>
        <h1>{title}</h1>
        <div className="paragraphs-body">
          <p className="p1text">{p1Text}</p>
          <p className="p2text">{p2Text}</p>
          <p className="p3text">{p3Text}</p>
        </div>

        <div className="blank-div"></div>
      </div>
    );
  }

  function ContactReturn() {
    return (
      <div className="body-container-flex">
        <div className="text-body">
          <div className="body-headers">
            <h2>{subtitle}</h2>
            <h1>{title}</h1>
            <p className="p1text">{p1Text}</p>
            <a className="p2text" href="email:eric@pattersoncandr.com">
              {p2Text}
            </a>
            <a className="p3text" href="email:eric@pattersoncandr.com">
              {p3Text}
            </a>
          </div>
          <ContactForm />
        </div>
      </div>
    );
  }

  function AboutReturn() {
    return (
      <div className="body-container-flex">
        <div className="text-body">
          <div className="body-headers">
            <h2>{subtitle}</h2>
            <h1>{title}</h1>
          </div>
          <div className="paragraphs-body">
            <p className="p1text">{p1Text}</p>
            <p className="p2text">{p2Text}</p>
            <p className="p3text">{p3Text}</p>
          </div>
        </div>
      </div>
    );
  }

  function ServicesReturn() {
    return (
      <div className="text-body">
        <div className="body-headers">
          <div className="thin-line">
            <h2>{subtitle}</h2>
          </div>
          <h1>{title}</h1>
        </div>
        <div className="paragraphs-body">
          <ul className="service-ul">
            <li className="servces-li">Home Additions</li>
            <li className="servces-li">Garage Construction</li>
            <li className="servces-li">Basement Finishing</li>
            <li className="servces-li">Bathroom and Kitchen Remodelling</li>
            <li className="servces-li">Deck, Fence, and Shed Construction</li>
            <li className="servces-li">Custom Tile Showers</li>
            <li className="servces-li">
              Flooring (LVP, Tile, Hardwood) Trim and Baseboard
            </li>
            <li className="servces-li">Window Installation</li>
            <li className="servces-li">Vinyl Siding</li>
            <li className="servces-li">And More…</li>
          </ul>
        </div>
      </div>
    );
  }

  function ReturnOrder() {
    if (pageNumber === "about") {
      return <AboutReturn />;
    } else if (pageNumber === "contact") {
      return <ContactReturn />;
    } else if (pageNumber === "services") {
      return (
        <div className="body-container-flex">
          <ServicesReturn />
          <ImageGallery />
        </div>
      );
    } else if (pageValue === "even") {
      return (
        <div className="body-container-flex">
          <ParagraphPage />
          <ImageGallery />
        </div>
      );
    } else {
      return (
        <div className="body-container-flex">
          <ImageGallery />
          <ParagraphPage />
        </div>
      );
    }
  }

  console.log(pageNumber + " " + pageValue);
  return (
    <section id={customid} className="appsection-scroller body-section">
      <BodyHeader />
      <BodyChevUp />
      <div
        className={
          pageNumber === "about"
            ? "about-background"
            : pageNumber === "services"
            ? "services-background"
            : pageNumber === "last"
            ? "contact-background"
            : pageValue === "even"
            ? `even-background ${backgroundimage}`
            : `odd-background ${backgroundimage}`
        }
      >
        <ReturnOrder />
      </div>

      <CallMeButton />
      <div>
        <BodyChevDown />
      </div>
    </section>
  );
}
