import React from "react";
import "../styles/Dropdown.css";

import IgIcon from "../Images/ig-icon01.png";
import FbIcon from "../Images/fb-icon01.png";
import Envelope from "../icons/envelope.svg";
import Phone from "../icons/phone.svg";
import data from "../JSON/pagelayout.json"; // Import your JSON data
import Chevrondown from "../icons/menu-svg.svg";
import Chevronup from "../icons/close-svg.svg";

export default function DropDown() {
  const clickUpandDown = (e) => {
    e.preventDefault();
    const img = e.target; // Get the clicked image element
    const bar = document.querySelector(".mobilebar");
    const sectionScrollers = document.querySelectorAll(".body-section");

    // Loop through each "body-section" element
    sectionScrollers.forEach((sectionScroller) => {
      // Toggle the class based on its presence
      if (sectionScroller.classList.contains("appsection-scroller")) {
        sectionScroller.classList.remove("appsection-scroller");
      } else {
        sectionScroller.classList.add("appsection-scroller");
      }
    });

    // Toggle the image source and class
    if (img.classList.contains("icondown")) {
      img.setAttribute("src", Chevronup);
      img.classList.replace("icondown", "iconup");
      bar.classList.add("topbarshow");
    } else {
      img.setAttribute("src", Chevrondown);
      img.classList.replace("iconup", "icondown");
      bar.classList.remove("topbarshow");
    }
  };

  function closeMenu() {
    const img = document.getElementById("upanddown");
    // Get the image element
    // Get the clicked image element
    // Toggle the image source and class
    img.setAttribute("src", Chevrondown);
    img.classList.replace("iconup", "icondown");
    // Remove the topbarshow class from the mobile bar
    const bar = document.querySelector(".mobilebar");
    bar.classList.remove("topbarshow");
    // Add or remove the appsection-scroller class from body-section elements
    const sectionScrollers = document.querySelectorAll(".body-section");
    sectionScrollers.forEach((sectionScroller) => {
      sectionScroller.classList.toggle("appsection-scroller");
    });
  }

  const subtitles = Object.values(data).map((section, index) => ({
    subtitle: index === 0 ? "About" : section.subtitle,
    customid: section.customid,
  }));

  return (
    <section className="HeaderLinkBar">
      <nav className="mobilebar">
        <ul className="headerdropdown">
          <li>
            <div>
              <img className="zondicon" src={Envelope} alt="MAIL"></img>

              <a
                className="dropdown-link"
                href="mailto:inbox@pattersoncandr.ca"
              >
                {" "}
                <b className="colons">:&nbsp;</b>
                inbox@pattersoncandr.ca
              </a>
            </div>
          </li>
          <li></li>
          <div>
            <img className="zondicon" src={Phone} alt="PHONE"></img>

            <a className="dropdown-link" href="tel:7054935330">
              <b className="colons">:&nbsp;</b>
              705.493.5330
            </a>
          </div>
          <li className="iconGroup">
            <a
              href="https://www.instagram.com/pattersoncandr/"
              title="Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <img className="igicon icons" src={IgIcon} height="25px" />
            </a>
            <a
              href="https://www.facebook.com/Patterson-Construction-and-Renovation-116829413998933/"
              title="Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <img className="fbicon icons" src={FbIcon} height="25px" />
            </a>
          </li>
        </ul>
        <ul className="menudropdown">
          {subtitles.map(({ subtitle, customid }, index) => (
            <li key={index}>
              <a
                className="menu-link"
                href={`#${customid}`}
                onClick={closeMenu}
              >
                {subtitle}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <img
        onClick={clickUpandDown}
        className="icondown"
        id="upanddown"
        src={Chevrondown}
        alt="CHEVUP"
      ></img>
    </section>
  );
}
