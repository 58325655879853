import React from "react";
import "../styles/iconToggle.css";

function IconToggle() {
  return (
    <section className="TitleBar">
      <div className="icon-container"></div>
      <div className="siteLogo">
        <h2 className="header">Patterson</h2>
        <h1 className="header">Construction and Renovation</h1>
      </div>
    </section>
  );
}

export default IconToggle;
