import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if reCAPTCHA is filled
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA validation.");
      return;
    }

    try {
      // Attempt to send the email
      const response = await axios.post(
        "https://pattcanrmailer.azurewebsites.net/send-email",
        formData
      );

      console.log("Axios response:", response);

      if (
        response.status === 200 &&
        response.data.includes("Email sent: 250 2.0.0 OK")
      ) {
        setIsSubmitted(true); // Set the flag to true on successful submission
      } else {
        console.error("Error sending email:", response.data);
        alert("Error sending email. Please try again later.");
      }
    } catch (error) {
      // Log the error to the console
      console.error("AxiosError:", error);

      // Check if error.response exists
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }

      alert("Error sending email. Please try again later.");
    }
  };

  function handleFocus(e) {
    const label = e.target.parentNode.querySelector("label");
    label.classList.add("hide-label");
  }

  function handleBlur(e) {
    const input = e.target;
    const label = input.parentNode.querySelector("label");
    if (!input.value.trim()) {
      label.classList.remove("hide-label");
    }
  }

  return (
    <div className="contact-form">
      {isSubmitted ? (
        <p>Thank you for submitting!</p>
      ) : (
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="textarea-container">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                placeholder=" "
              />
              <label>Name:</label>
            </div>
            <div className="textarea-container">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                placeholder=" "
              />
              <label>Email:</label>
            </div>
            <div className="textarea-container">
              <input
                type="tel"
                name="phone"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                placeholder=" "
              />
              <label>Phone:</label>
            </div>
            <div className="textarea-container">
              <textarea
                name="message"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                rows="4"
                cols="40"
                placeholder=" "
              ></textarea>
              <label>Message:</label>
            </div>
            {/* Add reCAPTCHA component */}
            <ReCAPTCHA
              sitekey="6LdjNTkpAAAAAGrS-jHRY9DBh5jFrmRVK6pD1CP9"
              onChange={(value) => setRecaptchaValue(value)}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
