import React from "react";
import DropDown from "./scripts/dropdown";
import ReturnFooter from "./pages/foot.js";
import ReturnBody from "./pages/body.js";
import ReturnBodyStyle from "./functions/Returnbodystyle.js";
import pageLayout from "./JSON/pagelayout.json";

function App() {
  const pageKeys = Object.keys(pageLayout);
  console.log(pageKeys);

  const pages = pageKeys.map((key, index) => {
    return <ReturnBody key={index} {...ReturnBodyStyle(index)} />;
  });

  return (
    <section>
      <header className="headersize">
        <DropDown />
      </header>
      <article id="appbody" className="appbody-scroller">
        {pages}
      </article>
      <footer>
        <ReturnFooter />
      </footer>
    </section>
  );
}

export default App;
