import jsonData from "../JSON/pagelayout.json";

export default function ReturnBodyStyle(pagenumber) {
  const dataKeys = Object.keys(jsonData);

  const lastPageIndex = dataKeys.length - 1;
  dataKeys.forEach((key, index) => {
    if (index === 0) {
      jsonData[key].pageNumber = "about";
    } else if (index === 1) {
      jsonData[key].pageNumber = "contact";
    } else if (index === 2) {
      jsonData[key].pageNumber = "services";
    } else if (index === lastPageIndex) {
      jsonData[key].pageNumber = `last ${index % 2 === 0 ? "even" : "odd"}`;
    } else {
      jsonData[key].pageNumber = `${index}`;
      jsonData[key].pageValue = `${index}` % 2 === 0 ? "even" : "odd";
    }
  });

  if (pagenumber >= 0 && pagenumber < dataKeys.length) {
    const selectedKey = dataKeys[pagenumber];
    return jsonData[selectedKey];
  } else {
    // Handle the case where the provided page number is out of range
    console.error("Invalid page number");
    return null; // You can return some default value or handle it accordingly
  }
}
