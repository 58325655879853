import React from "react";
import "../styles/Foot.css";
import logonowhite from "../Images/logo-no-white.png";
import fbicon from "../Images/fb-icon01.png";
import igicon from "../Images/ig-icon01.png";

export default function ReturnFooter() {
  return (
    <div className="footer">
      <div className="footerdata">
        <img src={logonowhite} />
        <p>
          Patterson Construction and Renovation is located in North Bay and
          surrounding Area. Contact us today for quotes and availability.
        </p>
        <div className="icongroup">
          <a
            href="https://www.facebook.com/Patterson-Construction-and-Renovation-116829413998933/"
            title="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <img src={fbicon} className="fbicon icons" />
          </a>
          <a
            href="https://www.instagram.com/pattersoncandr/"
            title="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <img src={igicon} className="igicon icons" />
          </a>
        </div>
        <div className="footer-phonebox"></div>
      </div>
    </div>
  );
}
